/* eslint-disable */
export enum Auth_Role {
  Admin = 'ADMIN',
  DebugUserOnly = 'DEBUG_USER_ONLY',
  InternalOnly = 'INTERNAL_ONLY',
  Unknown = 'UNKNOWN',
  Viewer = 'VIEWER',
  ViewerNonblocked = 'VIEWER_NONBLOCKED',
}

export enum AddressKind {
  /** 건물 */
  Building = 'BUILDING',
  /** 집합건물 */
  GroupBuilding = 'GROUP_BUILDING',
  /** 토지 */
  Land = 'LAND',
}

export enum AdvertisementStatusEnum {
  /** 전체 예산 초과 */
  BudgetExceeded = 'BudgetExceeded',
  /** 광고 삭제 */
  Deleted = 'DELETED',
  /** 승인 거절 */
  Denied = 'DENIED',
  /** 일 예산 초과 */
  DailyBudgetExceeded = 'DailyBudgetExceeded',
  /**
   * 광고 종료
   * @deprecated 사용하지 않음
   */
  Finished = 'FINISHED',
  /** 광고중 */
  Ongoing = 'ONGOING',
  /** 기간 종료 */
  Outdated = 'Outdated',
  /** 일시중지 */
  Paused = 'PAUSED',
  /** 승인 대기 */
  Ready = 'READY',
  /** 광고 차단 */
  Stopped = 'STOPPED',
  /** 광고 시작 전 */
  StartOutdated = 'StartOutdated',
}

export enum AdvertiserMode {
  /** 일반 */
  Lite = 'LITE',
  /** 프로 */
  Pro = 'PRO',
}

export enum ArticleAdvertisementPlacementEnum {
  /** 게시글 하단 더보기 */
  ArticleDetailMore = 'ARTICLE_DETAIL_MORE',
  /** 메인화면 리스트 안에 */
  InMainList = 'IN_MAIN_LIST',
  /** 메인화면 게시글 상단 케로셀 */
  MainListTopCarousel = 'MAIN_LIST_TOP_CAROUSEL',
}

export enum ArticleBuildingOrientationEnum {
  /** 동향 */
  EastFacing = 'EAST_FACING',
  /** 북동향 */
  NorthEastFacing = 'NORTH_EAST_FACING',
  /** 북향 */
  NorthFacing = 'NORTH_FACING',
  /** 북서향 */
  NorthWestFacing = 'NORTH_WEST_FACING',
  /** 남동향 */
  SouthEastFacing = 'SOUTH_EAST_FACING',
  /** 남향 */
  SouthFacing = 'SOUTH_FACING',
  /** 남서향 */
  SouthWestFacing = 'SOUTH_WEST_FACING',
  /** 서향 */
  WestFacing = 'WEST_FACING',
}

export enum ArticleDetailViewErrorTypeEnum {
  /** 게시글이 존재하지 않음 */
  NotFound = 'NOT_FOUND',
}

export enum ArticleInvisibleReasonEnum {
  /** 관리자 모니터 차단 */
  AdminMonitorBlock = 'ADMIN_MONITOR_BLOCK',
  /** 광고 차단됨 */
  AdvertisementBlocked = 'ADVERTISEMENT_BLOCKED',
  /** 비즈 프로필 후검수 거절 */
  BizProfileDeny = 'BIZ_PROFILE_DENY',
  /** 비즈 프로필 소유자 미인증 */
  BizProfileOwnerNotVerified = 'BIZ_PROFILE_OWNER_NOT_VERIFIED',
  /** 비즈 프로필 등록 필요에 의한 게시글 차단 */
  BizProfileRegistrationRequired = 'BIZ_PROFILE_REGISTRATION_REQUIRED',
  /** 체크 거부 */
  CheckDeny = 'CHECK_DENY',
  /** 등기부 위험 키워드 감지 */
  CorDangerousKeywordDetected = 'COR_DANGEROUS_KEYWORD_DETECTED',
  /** 임대 주택 */
  RentalHouseDetected = 'RENTAL_HOUSE_DETECTED',
  /** 신고됨 */
  Reported = 'REPORTED',
  /** 자동룰에의한 차단 */
  RuleDeny = 'RULE_DENY',
  /** 점수 거부 */
  ScoreDeny = 'SCORE_DENY',
  /** 사용자 차단 */
  UserBlock = 'USER_BLOCK',
}

export enum ArticleInvisibleStatusEnum {
  /** 신고차단 */
  Blocked = 'BLOCKED',
  /** 승인거절 */
  CheckDeny = 'CHECK_DENY',
  /** 미노출 안됨 */
  None = 'NONE',
}

/** 관리비 옵션 */
export enum ArticleManageCostOptionEnum {
  /** 난방 */
  Boiler = 'BOILER',
  /** 청소 */
  Cleaning = 'CLEANING',
  /** 전기 */
  Electric = 'ELECTRIC',
  /** 엘레베이터 */
  Elevator = 'ELEVATOR',
  /** 가스 */
  Gas = 'GAS',
  /** 인터넷 */
  Internet = 'INTERNET',
  /** 주차 */
  Parking = 'PARKING',
  /** 유선 TV */
  Tv = 'TV',
  /** 수도 */
  Waterworks = 'WATERWORKS',
}

/** 매물 옵션 이름 */
export enum ArticleOptionNameEnum {
  /** 에어컨 */
  Aircon = 'AIRCON',
  /** 침대 */
  Bed = 'BED',
  /** 전자레인지 */
  ElecRange = 'ELEC_RANGE',
  /** 엘리베이터 */
  Elevator = 'ELEVATOR',
  /** 냉장고 */
  Fridge = 'FRIDGE',
  /** 가스레인지 */
  GasRange = 'GAS_RANGE',
  /** 인덕션 */
  Induction = 'INDUCTION',
  /** 복층 */
  Loft = 'LOFT',
  /** 대출 */
  Mortgage = 'MORTGAGE',
  /** 주차 */
  Parking = 'PARKING',
  /** 애완동물 */
  Pet = 'PET',
  /** 옥탑 */
  Rooftop = 'ROOFTOP',
  /** 세탁기 */
  Washer = 'WASHER',
}

/** 매물 옵션 값 */
export enum ArticleOptionValueEnum {
  /** 확인 필요 */
  DontKnow = 'DONT_KNOW',
  /** 옵션 해당하지 않음 */
  No = 'NO',
  /** 옵션 해당 */
  Yes = 'YES',
}

export enum ArticlePointOrderDirection {
  /** 오름차순 */
  Asc = 'ASC',
  /** 내림차순 */
  Desc = 'DESC',
}

export enum ArticlePointOrderKey {
  /** 최신순 */
  Latest = 'LATEST',
  /** 끌올 순 */
  PublishedAt = 'PUBLISHED_AT',
  /** 거래완료까지 걸린 시간순 */
  TradedElapsedHours = 'TRADED_ELAPSED_HOURS',
  /** 관심순 */
  Watch = 'WATCH',
}

export enum ArticleQualitativeItemEnum {
  /** 주변보다 저렴해요 */
  AffordablePrice = 'AFFORDABLE_PRICE',
  /** 집 상태가 깨끗해요 */
  CleanCondition = 'CLEAN_CONDITION',
  /** 교통이 편리해요 */
  ConvenientTransport = 'CONVENIENT_TRANSPORT',
  /** 방음이 잘돼요 */
  GoodSoundproofing = 'GOOD_SOUNDPROOFING',
  /** 햇빛이 잘 들어요 */
  GoodSunlight = 'GOOD_SUNLIGHT',
  /** 주변 편의시설이 많아요 */
  ManyAmenities = 'MANY_AMENITIES',
  /** 전망이 좋아요 */
  NiceView = 'NICE_VIEW',
  /** 벌레가 없어요 */
  NoPests = 'NO_PESTS',
  /** 혼자 살아도 안전해요 */
  SafeForSolo = 'SAFE_FOR_SOLO',
  /** 테라스가 있어요 */
  WithTerrace = 'WITH_TERRACE',
}

export enum ArticleRecheckProcessEnum {
  /** 재검수중 */
  InProgress = 'IN_PROGRESS',
  /** 재검수 해당사항 없음 */
  None = 'NONE',
  /** 재검수 요청 가능 */
  Requestable = 'REQUESTABLE',
}

/** 게시글 거래 상태 */
export enum ArticleStatusEnum {
  /** 판매중 */
  OnGoing = 'ON_GOING',
  /** 예약중 */
  Reserved = 'RESERVED',
  /** 거래완료 */
  Traded = 'TRADED',
}

/** 내 비즈프로필 매물 필터를 위한 enum */
export enum BizProfileArticleStatusInputEnum {
  /** 숨김(게시글 숨김상태 뿐만 아니라 승인거절/차단을 포함) */
  Hidden = 'HIDDEN',
  /** 판매중 */
  OnSales = 'ON_SALES',
  /** 거래완료 */
  Traded = 'TRADED',
}

/** 소유자/운영자 구분 */
export enum BizProfileBrokerRoleEnum {
  /** 운영자 */
  Admin = 'ADMIN',
  /** 소유자 */
  Owner = 'OWNER',
}

/** 비즈프로필 리뷰 정렬 기준 */
export enum BizProfileReviewSortType {
  /** 본문 있는 리뷰 우선 정렬 */
  CommentFirst = 'COMMENT_FIRST',
  /** 최신순 정렬 */
  Latest = 'LATEST',
}

/** 비즈프로필 계정 상태 */
export enum BizProfileStatusEnum {
  /** 활성 */
  Active = 'ACTIVE',
  /** 비활성 */
  Inactive = 'INACTIVE',
  /** 심사중 */
  Pending = 'PENDING',
}

export enum BrokerPreopenSurveyMainSalesTypeEnum {
  /** 아파트 */
  Apart = 'APART',
  /** 기타 */
  Etc = 'ETC',
  /** 오피스텔 */
  Officetel = 'OFFICETEL',
  /** 원투룸 */
  OneTwoRoom = 'ONE_TWO_ROOM',
  /** 상가 */
  Store = 'STORE',
}

/** 사용 승인일 */
export enum BuildingApprovalDateFilterEnum {
  /** 2년 이하 */
  Less_2Years = 'LESS_2YEARS',
  /** 5년 이하 */
  Less_5Years = 'LESS_5YEARS',
  /** 10년 이하 */
  Less_10Years = 'LESS_10YEARS',
  /** 15년 이하 */
  Less_15Years = 'LESS_15YEARS',
  /** 15년 이상 */
  More_15Years = 'MORE_15YEARS',
}

/** 건물 용도 타입을 위한 Enum */
export enum BuildingUsageEnum {
  /** 숙박시설 */
  AccommodationFacility = 'ACCOMMODATION_FACILITY',
  /** 방송통신시설 */
  BroadcastingAndCommunicationFacility = 'BROADCASTING_AND_COMMUNICATION_FACILITY',
  /** 자동차관련시설 */
  CarRelatedFacility = 'CAR_RELATED_FACILITY',
  /** 묘지관련시설 */
  CemeteryRelatedFacility = 'CEMETERY_RELATED_FACILITY',
  /** 교정및군사시설 */
  CorrectionAndMilitaryFacility = 'CORRECTION_AND_MILITARY_FACILITY',
  /** 문화 및 집회시설 */
  CulturalAndAssemblyFacilities = 'CULTURAL_AND_ASSEMBLY_FACILITIES',
  /** 동식물관련시설 */
  DoorAndPlantRelatedFacility = 'DOOR_AND_PLANT_RELATED_FACILITY',
  /** 교육연구시설 */
  EducationAndResearchFacility = 'EDUCATION_AND_RESEARCH_FACILITY',
  /** 노유자시설 */
  ElderlyFacility = 'ELDERLY_FACILITY',
  /** 운동시설 */
  ExerciseFacility = 'EXERCISE_FACILITY',
  /** 공장 */
  Factory = 'FACTORY',
  /** 장례식장 */
  FuneralHome = 'FUNERAL_HOME',
  /** 위험물저장및처리시설 */
  HazardousMaterialFacility = 'HAZARDOUS_MATERIAL_FACILITY',
  /** 의료시설 */
  MedicalFacility = 'MEDICAL_FACILITY',
  /** 업무시설 */
  OfficeFacility = 'OFFICE_FACILITY',
  /** 발전시설 */
  PowerGenerationFacility = 'POWER_GENERATION_FACILITY',
  /** 공동주택 */
  PublicHousing = 'PUBLIC_HOUSING',
  /** 위락시설 */
  RecreationFacility = 'RECREATION_FACILITY',
  /** 종교시설 */
  ReligiousFacility = 'RELIGIOUS_FACILITY',
  /** 판매시설 */
  SaleFacility = 'SALE_FACILITY',
  /** 분뇨쓰레기처리시설 */
  SewageAndWasteProcessingFacility = 'SEWAGE_AND_WASTE_PROCESSING_FACILITY',
  /** 단독주택 */
  SingleFamilyHousing = 'SINGLE_FAMILY_HOUSING',
  /** 수련시설 */
  StudyFacility = 'STUDY_FACILITY',
  /** 가설건축물 */
  TemporaryBuilding = 'TEMPORARY_BUILDING',
  /** 관광휴게시설 */
  TourismAndRecreationFacility = 'TOURISM_AND_RECREATION_FACILITY',
  /** 운수시설 */
  TransportationFacility = 'TRANSPORTATION_FACILITY',
  /** 제1종 근린생활시설 */
  Type_1NeighborhoodLivingFacility = 'TYPE_1_NEIGHBORHOOD_LIVING_FACILITY',
  /** 제2종 근린생활시설 */
  Type_2NeighborhoodLivingFacility = 'TYPE_2_NEIGHBORHOOD_LIVING_FACILITY',
  /** 창고시설 */
  WarehouseFacility = 'WAREHOUSE_FACILITY',
}

export enum CollectAdvertisementConversionActionTypeEnum {
  /** 전화 */
  Call = 'CALL',
  /** 채팅 */
  Chat = 'CHAT',
}

/** 거래 방식 */
export enum DealTypeEnum {
  /** 중개사 */
  Broker = 'BROKER',
  /** 직거래 */
  Direct = 'DIRECT',
}

export enum DraftOriginFormTypeEnum {
  /** [기존 글쓰기폼] 이탈로 저장된 글 */
  ArticleDrafted = 'ARTICLE_DRAFTED',
  /** [V3] 드래프트 */
  DraftV3 = 'DRAFT_V3',
  /** 우리집 얼마나 빨리팔릴까 드래프트 */
  FastSellEvent = 'FAST_SELL_EVENT',
}

export enum ErrorMessageKey {
  /** 광고를 생성했지만 알 수 없는 오류가 발생했어요. */
  AdvertisementCreateButCantGet = 'ADVERTISEMENT_CREATE_BUT_CANT_GET',
  /** 사진을 추가하면 숨김을 해제할 수 있어요. */
  CantUnhideNonImageArticle = 'CANT_UNHIDE_NON_IMAGE_ARTICLE',
  /** 소유자 승인 후에 노출할 수 있어요. */
  CantUnhindeUnapprovedBizProfileArticle = 'CANT_UNHINDE_UNAPPROVED_BIZ_PROFILE_ARTICLE',
  /** 본인인증된 유저만 작성할 수 있어요. */
  OnlyIdentificationVerifiedWriterCanWrite = 'ONLY_IDENTIFICATION_VERIFIED_WRITER_CAN_WRITE',
  /** 이미 리뷰가 존재해요. */
  VisitReviewAlreadyExists = 'VISIT_REVIEW_ALREADY_EXISTS',
  /** 리뷰 요청 채팅이 이미 전송되었어요. */
  VisitReviewRequestChatAlreadySend = 'VISIT_REVIEW_REQUEST_CHAT_ALREADY_SEND',
}

export enum FakeSalesInfoReviewEnum {
  /** 설명보다 좋았어요 */
  BetterThanExplain = 'BETTER_THAN_EXPLAIN',
  /** 설명과 달랐어요 */
  Different = 'DIFFERENT',
  /** 허위매물이였어요. */
  FakeSales = 'FAKE_SALES',
  /** 설명과 동일했어요 */
  SameWithExplain = 'SAME_WITH_EXPLAIN',
}

export enum FeedbackKind {
  /** 당근 거래완료 판매자 */
  KarrotTradedSeller = 'KARROT_TRADED_SELLER',
  /** 거래못한 판매자 */
  NoneTradedSeller = 'NONE_TRADED_SELLER',
  /** 외부 거래완료 판매자 */
  OtherPlatformTradedSeller = 'OTHER_PLATFORM_TRADED_SELLER',
}

export enum FloorFilterEnum {
  /** 5층~9층 */
  FifthToNinth = 'FIFTH_TO_NINTH',
  /** 1층 */
  First = 'FIRST',
  /** 1층~4층 */
  FirstToForth = 'FIRST_TO_FORTH',
  /** 2층~5층 */
  SecondToFifth = 'SECOND_TO_FIFTH',
  /** 반지하 */
  SemiBasement = 'SEMI_BASEMENT',
  /** 6층~9층 */
  SixToNinth = 'SIX_TO_NINTH',
  /** 10층 이상 */
  TenthOrMore = 'TENTH_OR_MORE',
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Unspecified = 'UNSPECIFIED',
}

export enum GuideReviewType {
  /** 권리분석 가이드 */
  LandTitle = 'LAND_TITLE',
  /** 부동산 직거래 가이드 */
  RealtyGuide = 'REALTY_GUIDE',
}

export enum ImageQuality {
  /** 나쁨 */
  Bad = 'BAD',
  /** 좋음 */
  Good = 'GOOD',
}

export enum KontrolDeploymentProtocol {
  Grpc = 'grpc',
  Http = 'http',
}

export enum ListTopRecommendAgeRangeEnum {
  /** 20~24 */
  Age_20_24 = 'AGE_20_24',
  /** 25~29 */
  Age_25_29 = 'AGE_25_29',
  /** 30~34 */
  Age_30_34 = 'AGE_30_34',
  /** 35~39 */
  Age_35_39 = 'AGE_35_39',
  /** 40~49 */
  Age_40_49 = 'AGE_40_49',
  /** 50~ */
  Age_50 = 'AGE_50',
}

export enum ListTopRecommendArticleExperimentSegmentEnum {
  /** A그룹 */
  AGroup = 'A_GROUP',
  /** B그룹 */
  BGroup = 'B_GROUP',
  /** C그룹 */
  CGroup = 'C_GROUP',
}

export enum ListTopRecommendGenderEnum {
  /** 여성 */
  Female = 'Female',
  /** 남성 */
  Male = 'Male',
}

export enum ManageCostPayOptionEnum {
  /** 고정금액 */
  Fixed = 'FIXED',
  /** 확인 필요 */
  NeedToCheck = 'NEED_TO_CHECK',
  /** 관리비 없음 */
  None = 'NONE',
  /** 쓴 만큼 내는 요금 */
  Used = 'USED',
}

export enum MarketingBannerType {
  Banner = 'BANNER',
  Carousel = 'CAROUSEL',
}

export enum NotificationKind {
  /** 추천 */
  Recommend = 'RECOMMEND',
  /** 관심 FOMO 알림 */
  WatchFomo = 'WATCH_FOMO',
}

export enum OnboardingChatQuestionStepEnum {
  /** 채팅 종료 */
  End = 'END',
  /** 채팅 진행중 */
  OnGoing = 'ON_GOING',
}

export enum OnboardingChatRoleEnum {
  /** 챗봇 */
  Ai = 'AI',
  /** 사용자 */
  User = 'USER',
}

/** 원룸 타입 */
export enum OneRoomSubSalesType {
  /** 오픈형 원룸 */
  OpenRoom = 'OPEN_ROOM',
  /** 분리형 원룸 */
  SplitRoom = 'SPLIT_ROOM',
}

export enum OwnerTypeEnum {
  /** 단체 */
  Organization = 'ORGANIZATION',
  /** 개인 */
  Personal = 'PERSONAL',
}

export enum ParseRegistrationInvalidStatus {
  /** 파싱 실패 */
  Failure = 'FAILURE',
  /** 중개사무소 등록증과 사업자등록증 내용이 일치하지 않음 */
  InconsistentRegistrationContents = 'INCONSISTENT_REGISTRATION_CONTENTS',
  /** 유효하지 않은 사업자등록증 번호 */
  InvalidBusinessRegistrationNumber = 'INVALID_BUSINESS_REGISTRATION_NUMBER',
}

export enum RegionRangeEnum {
  /** 인접 지역 */
  Adjacent = 'ADJACENT',
  /** 내 지역(근처 폴리곤을 공유하는 리전 포함) */
  My = 'MY',
  /** range2 지역 */
  Range2 = 'RANGE2',
  /** range3 지역 */
  Range3 = 'RANGE3',
}

export enum SalesTypeEnum {
  /** 아파트 */
  Apart = 'APART',
  /** 기타 */
  Etc = 'ETC',
  /** 오피스텔 */
  Officetel = 'OFFICETEL',
  /** 오픈형 원룸 */
  OpenOneRoom = 'OPEN_ONE_ROOM',
  /** 분리형 원룸 */
  SplitOneRoom = 'SPLIT_ONE_ROOM',
  /** 상가 */
  Store = 'STORE',
  /** 투룸 */
  TwoRoom = 'TWO_ROOM',
}

/** 필터의 매물 타입 Enum */
export enum SalesTypeFilterEnum {
  /** 아파트 */
  Apart = 'APART',
  /** 기타 */
  Etc = 'ETC',
  /** 오피스텔 */
  Officetel = 'OFFICETEL',
  /** 원룸 */
  OneRoom = 'ONE_ROOM',
  /** 상가 */
  Store = 'STORE',
  /** 투룸 */
  TwoRoom = 'TWO_ROOM',
}

export enum SalesTypeInputEnum {
  /** 아파트 */
  Apart = 'APART',
  /** 기타 */
  Etc = 'ETC',
  /** 오피스텔 */
  Officetel = 'OFFICETEL',
  /** 오픈형 원룸 */
  OpenOneRoom = 'OPEN_ONE_ROOM',
  /** 분리형 원룸 */
  SplitOneRoom = 'SPLIT_ONE_ROOM',
  /** 상가 */
  Store = 'STORE',
  /** 투룸 */
  TwoRoom = 'TWO_ROOM',
}

export enum ScreenErrorTypeEnum {
  /** 게시글을 찾을 수 없음 */
  ArticleNotFound = 'ARTICLE_NOT_FOUND',
}

/** 검색 타입 열거형 */
export enum SearchDetailAddressType {
  Dong = 'dong',
  Floorho = 'floorho',
}

/** 매물 옵션 이름 */
export enum SearchPublicWebArticleOptionNameEnum {
  /** 에어컨 */
  Aircon = 'AIRCON',
  /** 침대 */
  Bed = 'BED',
  /** 전자레인지 */
  ElecRange = 'ELEC_RANGE',
  /** 엘리베이터 */
  Elevator = 'ELEVATOR',
  /** 냉장고 */
  Fridge = 'FRIDGE',
  /** 가스레인지 */
  GasRange = 'GAS_RANGE',
  /** 인덕션 */
  Induction = 'INDUCTION',
  /** 복층 */
  Loft = 'LOFT',
  /** 대출 */
  Mortgage = 'MORTGAGE',
  /** 즉시 입주 가능 */
  MoveInNow = 'MOVE_IN_NOW',
  /** 주차 */
  Parking = 'PARKING',
  /** 애완동물 */
  Pet = 'PET',
  /** 옥탑 */
  Rooftop = 'ROOFTOP',
  /** 세탁기 */
  Washer = 'WASHER',
}

export enum SearchPublicWebArticleSortEnum {
  /** 인기순 */
  Popular = 'POPULAR',
  /** 최신순 */
  Recently = 'RECENTLY',
}

export enum SmsVerifyCarrierEnum {
  /** KT */
  Kt = 'KT',
  /** KT 알뜰폰 */
  KtMvno = 'KT_MVNO',
  /** LG U+ */
  Lgu = 'LGU',
  /** LG U+ 알뜰폰 */
  LguMvno = 'LGU_MVNO',
  /** SKT */
  Skt = 'SKT',
  /** SKT 알뜰폰 */
  SktMvno = 'SKT_MVNO',
}

export enum SurveyType {
  /** 우리집 얼마나 빨리 팔릴까 이탈 설문 */
  FastSellEventLeave = 'FAST_SELL_EVENT_LEAVE',
}

export enum TradeProcessStatusEnum {
  /** 연락만 했어요. */
  OnlyContact = 'ONLY_CONTACT',
  /** 아니오, 보기만 했어요. */
  OnlyVisit = 'ONLY_VISIT',
  /** 네, 계약했어요 */
  TradeFinish = 'TRADE_FINISH',
}

export enum TradeTypeEnum {
  /** 전세 */
  Borrow = 'BORROW',
  /** 매매 */
  Buy = 'BUY',
  /** 월세 */
  Month = 'MONTH',
  /** 단기 */
  Short = 'SHORT',
  /** 연세 */
  Year = 'YEAR',
}

/** 필터의 거래타입 Enum */
export enum TradeTypeFilterEnum {
  /** 전세 */
  Borrow = 'BORROW',
  /** 매매 */
  Buy = 'BUY',
  /** 월세 */
  Month = 'MONTH',
  /** 단기 */
  Short = 'SHORT',
  /** 연세 */
  Year = 'YEAR',
}

export enum TradeTypeInputEnum {
  /** 전세 */
  Borrow = 'BORROW',
  /** 매매 */
  Buy = 'BUY',
  /** 월세 */
  Month = 'MONTH',
  /** 단기 */
  Short = 'SHORT',
  /** 연세 */
  Year = 'YEAR',
}

export enum TradedTypeSurveyEnum {
  /** 공인중개사 통해 거래했어요. */
  Broker = 'BROKER',
  /** 당근에서 거래했어요 */
  InKarrot = 'IN_KARROT',
  /** 다른 플랫폼에서 거래했어요 */
  OtherPlatform = 'OTHER_PLATFORM',
}

export enum UrgentArticleExperimentSegmentEnum {
  /** A그룹 */
  AGroup = 'A_GROUP',
  /** B그룹 */
  BGroup = 'B_GROUP',
}

export enum UserBlockType {
  KarrotBlock = 'KARROT_BLOCK',
  RealtyBlock = 'REALTY_BLOCK',
}

/** 나의 매물 필터를 위한 enum */
export enum ViewWriteArticleStatusInputEnum {
  /** 숨김(게시글 숨김상태 뿐만 아니라 승인거절/차단을 포함) */
  Hidden = 'HIDDEN',
  /** 판매중 */
  OnSales = 'ON_SALES',
  /** 거래완료 */
  Traded = 'TRADED',
}

export enum ViewerWriteFailureReason {
  /** 차단된 유저 */
  BlockedUser = 'BLOCKED_USER',
  /** 매물 개수 제한 */
  MaxArticleCountLimit = 'MAX_ARTICLE_COUNT_LIMIT',
  /** 미성년자 */
  MinorUserNotAllowed = 'MINOR_USER_NOT_ALLOWED',
}

/** 방문 후기 아이템 title type enum */
export enum VisitReviewItemEnum {
  /** 적극적으로 여러 매물을 찾아줘요. */
  ActiveFindRoom = 'ACTIVE_FIND_ROOM',
  /** 무리하게 가격을 깎아요 */
  DiscountTooMuch = 'DISCOUNT_TOO_MUCH',
  /** 허위매물을 광고해요. */
  FakeSales = 'FAKE_SALES',
  /** 응답이 빨라요 */
  FastResponse = 'FAST_RESPONSE',
  /** 친절하고 매너가 좋아요 */
  GoodManner = 'GOOD_MANNER',
  /** 조건과 다른 매물을 소개해줘요. */
  IntroduceDifferentRoom = 'INTRODUCE_DIFFERENT_ROOM',
  /** 조건에 맞는 매물을 소개해줘요. */
  IntroduceSuitableRoom = 'INTRODUCE_SUITABLE_ROOM',
  /** 시간 약속을 잘 지켜요 */
  KeepTime = 'KEEP_TIME',
  /** 매물 상태가 설명과 달라요 */
  LieRoom = 'LIE_ROOM',
  /** 거래 조건이 설명과 달라요 */
  LieTradeInfo = 'LIE_TRADE_INFO',
  /** 실제로 보니 더 마음에 들었어요 */
  MoreLike = 'MORE_LIKE',
  /** 시간 약속을 안지켰어요 */
  NotKeepTime = 'NOT_KEEP_TIME',
  /** 질문을 해도 답이 없어요 */
  NoResponse = 'NO_RESPONSE',
  /** 전문적이고 꼼꼼해요. */
  Professional = 'PROFESSIONAL',
  /** 응대가 불친절해요. */
  Rude = 'RUDE',
  /** 부동산 상태가 제공된 정보와 같아요 */
  SameInfo = 'SAME_INFO',
}

export enum VisitReviewRatingEnum {
  /** 별로에요 */
  Bad = 'BAD',
  /** 좋아요 */
  Good = 'GOOD',
  /** 최고에요 */
  VeryGood = 'VERY_GOOD',
}

/** 게시글 작성자 타입 */
export enum WriterTypeEnum {
  /** 중개사 */
  Broker = 'BROKER',
  /** 집주인 */
  Lessor = 'LESSOR',
  /** 세입자 */
  Tenant = 'TENANT',
}
