import {
  compose,
  truthy
} from "./chunk-X27G7BWE.mjs";

// src/utils/image.ts
import loadImage from "blueimp-load-image";
var loadImageAsCanvas = async (file) => {
  const data = await loadImage(file, {
    maxWidth: 1920,
    maxHeight: 1920,
    canvas: true
  });
  return data;
};

// src/utils/color.ts
function checkIsLightColor(color) {
  let r, g, b;
  if (color.match(/^rgb/)) {
    const rgb = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (!rgb) return false;
    r = rgb[1];
    g = rgb[2];
    b = rgb[3];
  } else {
    const hexColor = +("0x" + color.slice(1).replace(color.length < 5 ? /./g : "", "$&$&"));
    r = hexColor >> 16;
    g = hexColor >> 8 & 255;
    b = hexColor & 255;
  }
  const hsp = Math.sqrt(
    0.299 * (Number(r) * Number(r)) + 0.587 * (Number(g) * Number(g)) + 0.114 * (Number(b) * Number(b))
  );
  return hsp > 127.5;
}

// src/utils/masking.ts
var maskingPhoneNumber = (content) => {
  return content.replace(/\d{2}[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,11}/, " ***-****-**** ").replace(/[공일]{2}[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,}/, " ***-****-**** ").replace(/(0\d{2})[.-\s](\d{3,4})[.-\s](\d{3,4})/g, " ***-****-**** ");
};
var maskingKakaoId = (content) => {
  return content.replace(/http[s]?:\/\/open\.kakao\.com(?:\/petitions)?(?:\/[a-zA-Z0-9.]+)*/g, "*****").replace(/(?:카톡|카카오|kakao)[\S ]*[a-zA-Z0-9]{5,10}/g, "*****");
};
var maskingSensitiveContent = (content, options) => {
  const { kakaoId = true, phoneNumber = true } = options != null ? options : {};
  return compose(...[kakaoId && maskingKakaoId, phoneNumber && maskingPhoneNumber].filter(truthy))(
    content
  );
};

export {
  loadImageAsCanvas,
  checkIsLightColor,
  maskingSensitiveContent
};
