/* eslint-disable @typescript-eslint/ban-types */
import type { BrokerFaqBannerQuery$data } from '@/__generated__/BrokerFaqBannerQuery.graphql';
import type { EditArticleInputV3 } from '@/__generated__/editArticleMutation.graphql';
import type { CreateArticleInputV3 } from '@/__generated__/newPageMutation.graphql';
import type { ArticleFormFieldError } from '@/routes/_protected/articles/-components/ArticleForm';
import type { Section } from '@/routes/_protected/articles/-components/ReportBanner';
import type { Step } from '@/routes/_protected/articles/-components/RequestVerifyDialog';
import type { SalesHistoryStatus } from '@/routes/_protected/sales/-components/ChipList';
import type { ArticleFormInputType } from '@/scheme/article';
import type { FieldLoggerEventSchemas } from '@/logger/types/params';
import type { AddressFormState } from '@/routes/_protected/articles/-components/AddressField';
import { ChannelListDialog_chatChannel$data } from '@/__generated__/ChannelListDialog_chatChannel.graphql';

export type LoggerEventType = keyof LoggerEventSchema;
export type LoggerEventParams<T extends LoggerEventType> = LoggerEventSchema[T];

type EventType = 'click' | 'impression';
type EventProviderType = 'firebase' | 'ga4' | 'amplitude' | 'mixpanel';

export const DefaultEventProvider: Record<EventType, EventProviderType[]> = {
  click: ['amplitude'],
  impression: ['amplitude'],
};

/**
 * EventSchema - 이벤트별 타입을 정의
 */
export type LoggerEventSchema = {
  header_sales_my: {};
  header_sales_biz: {};
  click_article_item: { article_id: string; search_keyword?: string };
  click_article_action_trade: { article_id: string; search_keyword?: string };
  click_article_action_modify: { article_id: string; search_keyword?: string };
  click_sales_history_tab: { status: SalesHistoryStatus; path: string };
  show_article_qr_dialog: { article_id: string };
  click_gnb_chat: {};
  header_logo: {};
  header_new: {};
  complete_edit_article: { article_id: string; input: CreateArticleInputV3 | EditArticleInputV3 };
  click_submit: {};
  failed_submit: { errors: ArticleFormFieldError };
  create_article: {};
  complete_submit: {
    input: CreateArticleInputV3 | EditArticleInputV3;
  };
  click_write_more: {};
  click_request_verify: {};
  confirm_continue_writing: {};
  select_address: {
    address_form: AddressFormState;
  };
  click_ai_conversion_paste_button: {};
  click_ai_conversion_button: {};
  click_ai_conversion_start_button: {};
  click_ai_conversion_complete_button: {};
  click_broker_faq_banner: {};
  click_broker_notice: BrokerFaqBannerQuery$data['currentBrokerNotice'];
  click_report_banner: {
    section: Section;
    form?: ArticleFormInputType;
  };
  request_verify_dialog_click_request: {
    articleId: string;
  };
  request_verify_dialog_success: {
    articleId: string;
  };
  request_verify_dialog_fail: {
    articleId: string;
  };
  request_verify_dialog_bypass: {
    articleId: string;
  };
  request_verify_dialog_retry: {
    articleId: string;
  };
  request_verify_dialog_close: {
    articleId: string;
  };
  show_biz_profile_error: {};
  show_broker_faq_banner: {};
  show_broker_notice: {};
  request_verify_dialog: {
    articleId: string;
    step: Step;
  };
  click_article_new_biz_profile_owner_verify: {};
  click_sales_history_search_field: {};
  click_article_action_chat: { article_id: string; search_keyword?: string };
  click_article_action_chat_item: {
    article_id: string;
    channel: ChannelListDialog_chatChannel$data;
  };
} & FieldLoggerEventSchemas;

/**
 * EventProperties - 이벤트별 값을 정의
 */
export const LoggerEventProperties: {
  [K in keyof LoggerEventSchema]: {
    description: string;
    type: EventType;
  };
} = {
  header_sales_biz: {
    description: '헤더 > 중개소 매물 클릭',
    type: 'click',
  },
  header_sales_my: {
    description: '헤더 > 나의 매물 클릭',
    type: 'click',
  },
  click_article_item: {
    description: '아티클 아이템 클릭',
    type: 'click',
  },
  click_article_action_trade: {
    description: '(deprecated) 아티클 아이템 액션 > 거래 완료 버튼 클릭',
    type: 'click',
  },
  click_article_action_modify: {
    description: '아티클 아이템 액션 > 수정 버튼 클릭',
    type: 'click',
  },
  click_sales_history_tab: {
    description: '판매 상태 칩 > 칩 클릭',
    type: 'click',
  },
  show_article_qr_dialog: {
    description: '당근 앱으로 열기 다이얼로그 노출',
    type: 'impression',
  },
  click_gnb_chat: {
    description: '헤더 > PC에서 채팅하기 버튼 클릭',
    type: 'click',
  },
  header_logo: {
    description: '헤더 > 당근 로고 클릭',
    type: 'click',
  },
  header_new: {
    description: '헤더 > 매물 등록 클릭',
    type: 'click',
  },
  complete_edit_article: {
    description: '매물 수정 > 매물 수정 성공',
    type: 'click',
  },
  click_submit: {
    description: '매물 폼 > 등록/수정 버튼 클릭',
    type: 'click',
  },
  failed_submit: { description: '매물 폼 > validation 실패', type: 'click' },
  create_article: {
    description: '매물 등록 > 매물 등록 제출',
    type: 'click',
  },
  complete_submit: {
    description: '매물 등록 > 매물 등록 성공',
    type: 'click',
  },
  click_write_more: {
    description: '매물 등록 > 완료 > 매물 추가 등록하기 버튼 클릭',
    type: 'click',
  },
  click_request_verify: {
    description: '매물 등록 > 완료 >  집주인 인증 요청 버튼 클릭',
    type: 'click',
  },
  confirm_continue_writing: {
    description: '매물 등록 > 작성 중인 글 이어 쓰기 알럿 > 확인 클릭',
    type: 'click',
  },
  select_address: {
    description: '매물 등록 > 매물 주소 > 검색 결과 아이템 클릭',
    type: 'click',
  },
  click_ai_conversion_paste_button: {
    description: '매물 정보 간편 입력 다이얼로그 > 텍스트 붙여넣기 버튼 클릭',
    type: 'click',
  },
  click_ai_conversion_button: {
    description: '매물 등록 > 간편 매물 등록 버튼 클릭 (매물 정보 간편 입력 다이얼로그 열기)',
    type: 'click',
  },
  click_ai_conversion_start_button: {
    description: '매물 정보 간편 입력 다이얼로그 > 자동 입력 시작하기 버튼 클릭',
    type: 'click',
  },
  click_ai_conversion_complete_button: {
    description: '매물 정보 간편 입력 다이얼로그 > 입력 완료 버튼 클릭',
    type: 'click',
  },
  click_broker_faq_banner: {
    description: '매물 등록 > 중개사 FAQ 배너 클릭',
    type: 'click',
  },
  click_broker_notice: {
    description: '매물 등록 > 중개사 공지사항 배너 클릭',
    type: 'click',
  },
  click_report_banner: {
    description: '제안하기 배너 클릭',
    type: 'click',
  },
  request_verify_dialog_click_request: {
    description: '집주인 인증 요청 다이얼로그 > 집주인 인증 요청하기 버튼 클릭',
    type: 'click',
  },
  request_verify_dialog_success: {
    description: '집주인 인증 요청 다이얼로그 > 집주인 인증 요청 성공',
    type: 'click',
  },
  request_verify_dialog_fail: {
    description: '집주인 인증 요청 다이얼로그 > 집주인 인증 요청 실패',
    type: 'click',
  },
  request_verify_dialog_retry: {
    description: '집주인 인증 요청 다이얼로그 > 번호 다시 입력하기 버튼 클릭',
    type: 'click',
  },
  request_verify_dialog_bypass: {
    description: '집주인 인증 요청 다이얼로그 > 인증 없이 게시글 등록하기 버튼 클릭',
    type: 'click',
  },
  request_verify_dialog_close: {
    description: '집주인 인증 요청 다이얼로그 > 성공 3초 후 다이얼로그 자동 닫힘',
    type: 'click',
  },
  show_biz_profile_error: {
    description: '비즈프로필 가입이 필요해요 노출',
    type: 'impression',
  },
  show_broker_faq_banner: {
    description: '매물 등록 > 중개사 FAQ 배너 노출',
    type: 'impression',
  },
  show_broker_notice: {
    description: '매물 등록 > 중개사 공지사항 배너 노출',
    type: 'impression',
  },
  request_verify_dialog: {
    description: '집주인 인증 요청 다이얼로그 노출',
    type: 'impression',
  },
  address_field: {
    description: '매물 등록 > 매물 주소 필드 클릭',
    type: 'click',
  },
  address_info_field: {
    description: '매물 등록 > 위치 한줄 설명 필드 클릭',
    type: 'click',
  },
  building_approval_date_field: {
    description: '매물 등록 > 사용승인일 (연식) 필드 클릭',
    type: 'click',
  },
  '전용 면적_field': {
    description: '매물 등록 > 전용 면적 필드 클릭',
    type: 'click',
  },
  '공급 면적_field': {
    description: '매물 등록 > 공급 면적 필드 클릭',
    type: 'click',
  },
  building_usage_field: {
    description: '매물 등록 > 건축물 용도 필드 클릭',
    type: 'click',
  },
  content_field: {
    description: '매물 등록 > 매물 설명 필드 클릭',
    type: 'click',
  },
  image_field: {
    description: '매물 등록 > 사진 필드 클릭',
    type: 'click',
  },
  include_manage_cost_pay_option_field: {
    description: '매물 등록 > 관리비 항목 필드 클릭',
    type: 'click',
  },
  include_manage_cost_option_fixed_cost_field: {
    description: '매물 등록 > 관리비 항목 고정금액 필드 클릭',
    type: 'click',
  },
  manage_cost_field: {
    description: '매물 등록 > 관리비 필드 클릭',
    type: 'click',
  },
  etc_manage_cost_field: {
    description: '매물 등록 > 기타 관리비 필드 클릭',
    type: 'click',
  },
  move_in_date_field: {
    description: '매물 등록 > 입주 가능일 클릭',
    type: 'click',
  },
  can_move_in_now_field: {
    description: '매물 등록 > 즉시 입주 가능 필드 클릭',
    type: 'click',
  },
  option_field: {
    description: '매물 등록 > 내외부 시설 필드 클릭',
    type: 'click',
  },
  building_orientation_field: {
    description: '매물 등록 > 매물 방향 필드 클릭',
    type: 'click',
  },
  price_field: {
    description: '매물 등록 > 거래 방식별 금액 필드 클릭',
    type: 'click',
  },
  monthly_pay_field: {
    description: '매물 등록 > 거래 방식별 월세 금액 필드 클릭',
    type: 'click',
  },
  adjustable_field: {
    description: '매물 등록 > 보증금 조정 가능 필드 클릭',
    type: 'click',
  },
  trade_description_field: {
    description: '매물 등록 > 보증금 조정 설명 필드 클릭',
    type: 'click',
  },
  qualitative_field: {
    description: '매물 등록 > 매물 장점 필드 클릭',
    type: 'click',
  },
  required_option_field: {
    description: '매물 등록 > 필수 항목 필드 클릭',
    type: 'click',
  },
  parking_spots_field: {
    description: '매물 등록 > 필수 항목 > 주차 대수 필드 클릭',
    type: 'click',
  },
  room_field: {
    description: '매물 등록 > 방 개수 필드 클릭',
    type: 'click',
  },
  bathroom_field: {
    description: '매물 등록 > 욕실 개수 필드 클릭',
    type: 'click',
  },
  top_floor_field: {
    description: '매물 등록 > 건물 전체 층 필드 클릭',
    type: 'click',
  },
  floor_field: {
    description: '매물 등록 > 해당 층 필드 클릭',
    type: 'click',
  },
  semi_basement_field: {
    description: '매물 등록 > 반지하 필드 클릭',
    type: 'click',
  },
  sales_type_field: {
    description: '매물 등록 > 매물 종류 필드 클릭',
    type: 'click',
  },
  trade_type_field: {
    description: '매물 등록 > 거래 방식 필드 클릭',
    type: 'click',
  },
  video_field: {
    description: '매물 등록 > 동영상 필드 클릭',
    type: 'click',
  },
  click_article_new_biz_profile_owner_verify: {
    description: '매물 등록 > 개공 인증 버튼 클릭',
    type: 'click',
  },
  click_sales_history_search_field: {
    description: '판매내역 > 주소 검색 필드 클릭',
    type: 'click',
  },
  click_article_action_chat: {
    description: '아티클 아이템 액션 > 채팅하기 버튼 클릭',
    type: 'click',
  },
  click_article_action_chat_item: {
    description: '아티클 채팅 목록 다이얼로그 > 채팅 아이템 클릭',
    type: 'click',
  },
};
