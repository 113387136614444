/**
 * @generated SignedSource<<2d384ece2f540e84426835b698b90ce1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelListDialog_chatChannel$data = {
  readonly channelHash: string;
  readonly lastMessage: {
    readonly content: string;
  };
  readonly lastMessageTime: string;
  readonly receiver: {
    readonly checkInRegionV2: {
      readonly name: string;
    } | null;
    readonly nickname: string;
    readonly originalId: number;
    readonly profileImageUrl: string | null;
  };
  readonly unreadCount: number;
  readonly " $fragmentType": "ChannelListDialog_chatChannel";
};
export type ChannelListDialog_chatChannel$key = {
  readonly " $data"?: ChannelListDialog_chatChannel$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelListDialog_chatChannel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelListDialog_chatChannel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "channelHash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KarrotUser",
      "kind": "LinkedField",
      "name": "receiver",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nickname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "checkInRegionV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessage",
      "kind": "LinkedField",
      "name": "lastMessage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastMessageTime",
      "storageKey": null
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};

(node as any).hash = "f3220a620651e04d56dfbc7dedee70b7";

export default node;
