import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { memo, startTransition, useCallback, useEffect, useState } from 'react';
import { Dialog } from '../Dialog';
import { Avatar, Badge, Button } from '@daangn/carotene';
import { graphql, useFragment, useRefetchableFragment } from 'react-relay';
import { ChannelListDialog_article$key } from '@/__generated__/ChannelListDialog_article.graphql';
import { formatDistanceKo } from '@daangn/realty-sdk';
import { ChannelListDialog_chatChannel$key } from '@/__generated__/ChannelListDialog_chatChannel.graphql';
import { config } from '@/config';
import logger from '@/utils/Logger';
import { ChannelListDialogRefetchQuery } from '@/__generated__/ChannelListDialogRefetchQuery.graphql';

type Props = {
  articleRef: ChannelListDialog_article$key;
  onChatClick: () => void;
};

const ChannelListDialog = ({ articleRef, onChatClick }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [{ viewChatChannel, originalId }, refetch] = useRefetchableFragment<
    ChannelListDialogRefetchQuery,
    ChannelListDialog_article$key
  >(
    graphql`
      fragment ChannelListDialog_article on Article
      @refetchable(queryName: "ChannelListDialogRefetchQuery") {
        originalId
        viewChatChannel {
          unreadCount
          ...ChannelListDialog_chatChannel
        }
      }
    `,
    articleRef
  );

  const unreadCount = viewChatChannel?.reduce((acc, cur) => acc + cur.unreadCount, 0);

  const refetchChannelItem = useCallback(() => {
    startTransition(() => {
      refetch({}, { fetchPolicy: 'network-only' });
    });
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    const visibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchChannelItem();
      }
    };

    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [isOpen]);

  return (
    <Dialog.Root onOpenChange={(open) => setIsOpen(open)} open={isOpen}>
      <Dialog.Trigger asChild>
        <Button variant="neutral" size="large" className="flex-1" onClick={onChatClick}>
          채팅하기 {unreadCount > 0 && <>({unreadCount})</>}
        </Button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay />

        <Dialog.Content className="flex max-h-[560px] min-h-[320px] w-[560px] flex-col !px-0 !pt-0">
          <div className="border-bg-neutral flex justify-between border-b px-6 py-5">
            <Dialog.Title className="heading-medium">채팅 목록</Dialog.Title>

            <Dialog.Close>
              <IconXmarkLine width={24} height={24} />
            </Dialog.Close>
          </div>

          <Dialog.Description className="flex grow flex-col overflow-y-auto px-5">
            {!viewChatChannel.length ? (
              <EmptyChannelList />
            ) : (
              viewChatChannel.map((channel, i) => (
                <div key={i}>
                  <ChannelItem
                    channelRef={channel}
                    articleId={originalId}
                    refresh={refetchChannelItem}
                  />
                  {i !== viewChatChannel.length - 1 && (
                    <div className="border-bg-neutral border-b" />
                  )}
                </div>
              ))
            )}
          </Dialog.Description>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const EmptyChannelList = () => {
  return (
    <div className="body-small-default text-fg-neutralMuted flex grow items-center justify-center px-5 py-6">
      채팅 내역이 존재하지 않아요.
    </div>
  );
};

const ChannelItem = ({
  channelRef,
  articleId,
  refresh,
}: {
  channelRef: ChannelListDialog_chatChannel$key;
  articleId: string;
  refresh: () => void;
}) => {
  const channel = useFragment<ChannelListDialog_chatChannel$key>(
    graphql`
      fragment ChannelListDialog_chatChannel on ChatChannel {
        unreadCount
        channelHash
        receiver {
          originalId
          profileImageUrl
          nickname
          checkInRegionV2 {
            name
          }
        }
        lastMessage {
          content
        }
        lastMessageTime
      }
    `,
    channelRef
  );

  const handleChannelClick = () => {
    logger.track('click_article_action_chat_item', {
      article_id: articleId,
      channel: channel,
    });
    window.open(`${config.chatUrl}/room/${channel.channelHash}`, '_blank');
    setTimeout(() => refresh(), 300);
  };

  return (
    <div className="flex cursor-pointer gap-2 py-6" onClick={handleChannelClick}>
      <Avatar
        size={36}
        src={
          channel.receiver.profileImageUrl ||
          'https://assetstorage.krrt.io/1420322515413545053/7a76fa35-e24d-4b5e-9b09-d1ef8dc4524d/width=224,height=224.webp'
        }
        alt={channel.receiver.nickname}
      />
      <div className="flex-1">
        <div className="text-fg-neutral flex gap-1">
          <div className="heading-xsmall">{channel.receiver.nickname}</div>
          <span className="body-xsmall-default text-fg-neutralSubtle">
            {[channel.receiver.checkInRegionV2?.name, formatDistanceKo(channel.lastMessageTime)]
              .filter(Boolean)
              .join(' · ')}
          </span>
        </div>
        <div className="body-small-default line-clamp-1 text-ellipsis">
          {channel.lastMessage.content}
        </div>
      </div>
      {channel.unreadCount > 0 && (
        <Badge size="small" tone="brand" variant="solid" className="aspect-square rounded-full">
          {channel.unreadCount >= 10 ? '10+' : channel.unreadCount}
        </Badge>
      )}
    </div>
  );
};

export default memo(ChannelListDialog);
