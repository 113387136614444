import {
  bizProfileSalesHistoryListQuery,
  type bizProfileSalesHistoryListQuery$variables,
} from '@/__generated__/bizProfileSalesHistoryListQuery.graphql';
import MainEnvironment from '@/relay/environment';
import { createFileRoute, useLoaderData } from '@tanstack/react-router';
import { loadQuery, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { graphql } from 'relay-runtime';
import { Suspense, useState, useEffect } from 'react';
import ChipList, { SalesHistoryTabs, type SalesHistoryStatus } from './-components/ChipList';
import BizHistoryArticles from './-components/BizHistoryArticles';
import ArticleListSkeleton from '@/components/Skeleton/ArticleListSkeleton';
import ReportBanner from '../articles/-components/ReportBanner';
import { useSearch } from '@daangn/realty-react/hooks';
import { Button, TextField } from '@daangn/carotene';
import logger from '@/utils/Logger';

export const Route = createFileRoute('/_protected/sales/biz')({
  loader: async ({}) => {
    return loadQuery<bizProfileSalesHistoryListQuery>(
      MainEnvironment,
      BizProfileSalesHistoryListQuery,
      {}
    );
  },
  component: Page,
});

const BizProfileSalesHistoryListQuery = graphql`
  query bizProfileSalesHistoryListQuery($input: BizProfileArticleStatusGroupByCountInput) {
    bizProfileArticleStatusGroupByCount(input: $input) {
      count
      status
    }
  }
`;

function Page() {
  const appQueryRef = useLoaderData({ from: Route.id });
  const [queryRef, loadQuery] = useQueryLoader<bizProfileSalesHistoryListQuery>(
    BizProfileSalesHistoryListQuery,
    appQueryRef
  );

  if (!queryRef) throw new Error('게시글 정보를 불러오지 못했어요.');

  return <PageContent queryRef={queryRef} loadQuery={loadQuery} />;
}

type PageContentProps = {
  queryRef: PreloadedQuery<bizProfileSalesHistoryListQuery>;
  loadQuery: (variables: bizProfileSalesHistoryListQuery$variables) => void;
};
const PageContent = ({ loadQuery, queryRef }: PageContentProps) => {
  const { bizProfileArticleStatusGroupByCount } = usePreloadedQuery(
    BizProfileSalesHistoryListQuery,
    queryRef
  );

  const { input, setInput, query, forceSearch } = useSearch('');
  const searchKeyword = query.length >= 2 ? query : undefined;
  const isSearching = input.length >= 2 && input !== searchKeyword;

  const [selectedStatus, setSelectedStatus] = useState<SalesHistoryStatus>('ON_SALES');
  const handleChangeTab = (status: SalesHistoryStatus) => {
    setSelectedStatus(status);
    loadQuery({ input: { addressKeyword: searchKeyword } });
  };

  const handleClickSearchField = () => {
    logger.track('click_sales_history_search_field', { keyword: input });
  };

  useEffect(() => {
    loadQuery({ input: { addressKeyword: searchKeyword } });
  }, [searchKeyword]);

  const currentTabCount =
    bizProfileArticleStatusGroupByCount.find((item) => item.status === selectedStatus)?.count ?? 0;

  if (searchKeyword && currentTabCount === 0) {
    const firstNonEmptyTab = SalesHistoryTabs.find(
      (tab) =>
        (bizProfileArticleStatusGroupByCount.find((v) => v.status === tab.status)?.count ?? 0) > 0
    );

    firstNonEmptyTab && setSelectedStatus(firstNonEmptyTab.status);
  }

  return (
    <div className="flex flex-col gap-12 pb-36 pt-12">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1.5">
          <div className="heading-large">중개소 매물</div>
          <div className="body-xsmall-default text-fg-neutralMuted mb-4 mt-1.5">
            부동산 홈 우측 삼선 메뉴(≡)의 '나의 매물' 에서 중개소 매물을 관리할 수 있어요.
          </div>
        </div>

        {/* Chip */}
        <ChipList
          selectedStatus={selectedStatus}
          statusGroupByCount={bizProfileArticleStatusGroupByCount}
          onSelectedStatusChange={handleChangeTab}
        />

        <div className="flex items-center gap-3">
          <div className="w-full">
            <TextField
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onClick={handleClickSearchField}
              placeholder="지번, 도로명으로 매물을 검색할 수 있어요"
            />
          </div>
          <div className="shrink-0 self-stretch">
            <Button
              className="h-full"
              size="large"
              variant="neutral"
              onClick={() => {
                forceSearch(input);
              }}
            >
              검색
            </Button>
          </div>
        </div>
      </div>

      {/* Article */}
      {isSearching ? (
        <ArticleListSkeleton />
      ) : (
        <Suspense fallback={<ArticleListSkeleton />}>
          <BizHistoryArticles searchKeyword={searchKeyword} selectedStatus={selectedStatus} />
        </Suspense>
      )}

      <ReportBanner section="sales_biz" />
    </div>
  );
};
