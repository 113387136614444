/**
 * @generated SignedSource<<02b519e4afeb8f6bd8723b27a66f045c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelListDialogRefetchQuery$variables = {
  id: string;
};
export type ChannelListDialogRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ChannelListDialog_article">;
  } | null;
};
export type ChannelListDialogRefetchQuery = {
  response: ChannelListDialogRefetchQuery$data;
  variables: ChannelListDialogRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChannelListDialogRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChannelListDialog_article"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChannelListDialogRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatChannel",
                "kind": "LinkedField",
                "name": "viewChatChannel",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unreadCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "channelHash",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KarrotUser",
                    "kind": "LinkedField",
                    "name": "receiver",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profileImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nickname",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "checkInRegionV2",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatMessage",
                    "kind": "LinkedField",
                    "name": "lastMessage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastMessageTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Article",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f46216c02bfee89d280601e2f815bcae",
    "id": null,
    "metadata": {},
    "name": "ChannelListDialogRefetchQuery",
    "operationKind": "query",
    "text": "query ChannelListDialogRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ChannelListDialog_article\n    id\n  }\n}\n\nfragment ChannelListDialog_article on Article {\n  originalId\n  viewChatChannel {\n    unreadCount\n    ...ChannelListDialog_chatChannel\n  }\n  id\n}\n\nfragment ChannelListDialog_chatChannel on ChatChannel {\n  unreadCount\n  channelHash\n  receiver {\n    originalId\n    profileImageUrl\n    nickname\n    checkInRegionV2 {\n      name\n      id\n    }\n    id\n  }\n  lastMessage {\n    content\n  }\n  lastMessageTime\n}\n"
  }
};
})();

(node as any).hash = "7f1f739327ae8cb8f18f6e0872846a00";

export default node;
