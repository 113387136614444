import { BizHistoryArticles_BizProfileArticles$key } from '@/__generated__/BizHistoryArticles_BizProfileArticles.graphql';
import { BizHistoryArticlesQuery } from '@/__generated__/BizHistoryArticlesQuery.graphql';
import { BizProfileArticlesRefetchQuery } from '@/__generated__/BizProfileArticlesRefetchQuery.graphql';
import ArticleEmpty from '@/components/Article/ArticleEmpty';
import ArticleItem from '@/components/Article/ArticleItem';
import InfiniteLoading from '@/components/InfinteLoading';
import { BizProfileArticleStatusInputEnum } from '@/types/schemaEnums';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';

type BizHistoryArticlesProps = {
  selectedStatus: `${BizProfileArticleStatusInputEnum}`;
  searchKeyword?: string;
};
const BizHistoryArticles = ({ selectedStatus, searchKeyword }: BizHistoryArticlesProps) => {
  const queryRef = useLazyLoadQuery<BizHistoryArticlesQuery>(
    graphql`
      query BizHistoryArticlesQuery(
        $first: Int = 20
        $after: String
        $input: BizProfileArticlesInput
      ) {
        ...BizHistoryArticles_BizProfileArticles
          @arguments(first: $first, after: $after, input: $input)
      }
    `,
    { input: { status: selectedStatus, addressKeyword: searchKeyword } },
    { fetchPolicy: 'store-and-network' }
  );

  const { data, hasNext, loadNext } = usePaginationFragment<
    BizProfileArticlesRefetchQuery,
    BizHistoryArticles_BizProfileArticles$key
  >(
    graphql`
      fragment BizHistoryArticles_BizProfileArticles on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 20 }
        after: { type: String }
        input: { type: "BizProfileArticlesInput" }
      )
      @refetchable(queryName: "BizProfileArticlesRefetchQuery") {
        bizProfileArticles(first: $first, after: $after, input: $input)
          @connection(key: "BizProfileArticles_bizProfileArticles") {
          edges {
            node {
              id
              originalId
              status
              isHide
              invisibleStatus
              ...ArticleItem_article
            }
            cursor
          }
        }
      }
    `,
    queryRef
  );

  const { edges } = data.bizProfileArticles;
  const articles = edges.map((v) => v.node);

  if (articles.length < 1) {
    return <ArticleEmpty searchKeyword={searchKeyword} selectedStatus={selectedStatus} />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="grid w-full grid-cols-2 gap-8">
        {articles.map((article, index) => (
          <ArticleItem
            key={article.id}
            articleRef={article}
            index={index}
            showRealtyId
            searchKeyword={searchKeyword}
          />
        ))}
      </div>

      <InfiniteLoading hasNext={hasNext} onLoadMore={() => loadNext(20)} />
    </div>
  );
};

export default BizHistoryArticles;
